import i18n from '../i18n';
import { notify } from '@kyvg/vue3-notification';

const t = i18n.global.t;

export default (promise) => {
  promise.then(
    (res) => {
      if (res.status < 500) {
        return;
      }

      notify({
        type: 'danger',
        title: t('error.5xx.title'),
        text: t('error.5xx.general'),
        duration: -1,
      });
    },
    (err) => {
      notify({
        type: 'danger',
        title: t('error.5xx.title'),
        text: t('error.5xx.network'),
      });
    }
  );
};
