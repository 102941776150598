<template>
  <div>
    <router-view />
    <top-notifications data-test="alerts" />
    <modal-list />
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import ModalList from 'poronline-shared-vue-components/components/modals/modal-list';
import TopNotifications from 'poronline-shared-vue-components/components/top-notification';
import useWithProgressBar from 'poronline-shared-vue-components/composables/useWithProgressBar';

export default defineComponent({
  name: 'App',
  components: { ModalList, TopNotifications },
  setup() {
    useWithProgressBar();
  },
});
</script>
