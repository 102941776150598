import Vue from 'vue';
import { notify } from '@kyvg/vue3-notification';
import { getNotifications } from '../../services/api/notifications';

export default {
  state: {
    notified: false,
  },

  mutations: {
    SET_NOTIFIED: (state) => {
      state.notified = true;
    },
  },

  actions: {
    SHOW_NEW_NOTIFICATIONS: async ({ commit, state }) => {
      if (state.notified) {
        return;
      }

      try {
        const {
          data: { notifications },
        } = await getNotifications();
        const ids = [];

        notifications.forEach(({ id, title, message: text, type, duration }) => {
          notify({ title, text, type, duration: duration ?? -1 });
          ids.push(id);
        });

        const read =
          document.cookie
            .split(/; ?/)
            .find((c) => c.startsWith('connect.nids='))
            ?.split('=')[1] ?? [];

        document.cookie = Object.entries({
          'connect.nids': ids.concat(read).filter(Boolean).join(','),
          'max-age': 31536000,
          samesite: 'strict',
          secure: /https/.test(document.location.protocol),
        })
          .filter(([, value], idx) => 0 === idx || !!value)
          .map((entry) => (true === entry[1] ? entry[0] : entry.join('=')))
          .join(';');

        commit('SET_NOTIFIED');
      } catch {}
    },
  },
};
