import { createApp } from 'vue';
import App from './app.vue';

import router from './router';
import store from './store';

import './validators';
import './flatpickr';
import VWave from 'v-wave';
import VueScrollTo from 'vue-scrollto';
import Notifications from '@kyvg/vue3-notification';
import { markdownDirective } from './global-directives';
import i18n from './i18n';
import * as UserSnap from './usersnap';
import { addListener as addHttpListener } from './services/http';
import authListener from './services/api/auth-listener';
import progressListener from './services/api/progress-listener';
import errorListener from './services/error-listener';

import './styles/bootstrap.scss';
import './styles/poronline.scss';
import './styles/main.scss';

store.dispatch('INITIALIZE_TIME', i18n.global.locale);

addHttpListener(progressListener);
addHttpListener(authListener);
addHttpListener(errorListener);

// Vue.use(UserSnap, {
//   consoleRecorder: true,
//   consoleRecorderCfg: {
//     maxMessages: 500, // default 50
//     maxExceptions: 500, // default 50
//     logObjectSize: 20480, // default 2048 bytes
//   },
// });

const app = createApp(App);
app.config.errorHandler = (err, vm, info) => {
  throw err;
};
// Global directives
app.directive('markdown', markdownDirective);
app.use(router).use(store).use(i18n).use(Notifications).use(VueScrollTo).use(VWave).mount('#app');
