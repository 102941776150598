import { notify } from '@kyvg/vue3-notification';
import cloneDeep from 'lodash/cloneDeep';
import groupBy from 'lodash/groupBy';

import { createApplicationHttpPostBody } from '../../forms/customer-application';
import {
  getGenericRequestDetails,
  getOutsideThePetRequestDetails,
  getRequestRepairRequestDetails,
  postRequestOutsideThePet,
  postRequestRepair,
  postRequestGenericApplication,
  getRequestsList,
} from '../../services/api/forms';
import i18n from '../../i18n';
const t = i18n.global.t;

const deniedEvaluationStatuses = ['NOT_AGREED', 'NOT_SATISFACTORY'];

export default {
  state: {
    copyRequest: null,
    requestsLoading: false,
    requests: null,
    requestDetails: null,
  },
  getters: {
    COPY_REQUEST: (state) => state.copyRequest,
    REQUEST_DETAILS: (state) => state.requestDetails,
    GET_REQUESTS: (state) => state.requests,
    REQUESTS_LOADING: (state) => state.requestsLoading,
    REQUESTS_BY_URCN: (state) => groupBy(state.requests, (request) => request.ucrn),
    REQUESTS_BY_TYPE_ID: (state) =>
      (state.requests ?? []).reduce((result, request) => {
        const type = request.type.toUpperCase();
        if (!result[type]) {
          result[type] = {};
        }

        result[type][request.id] = request;
        return result;
      }, {}),
    DENIED_REQUESTS: (state) =>
      state.requests?.filter((request) => deniedEvaluationStatuses.includes(request.request.evaluationStatus)),
  },
  mutations: {
    SET_REQUESTS_LOADING: (state, value) => {
      state.requestsLoading = !!value;
    },
    SET_REQUESTS: (state, requests) => {
      const reqList = Object.freeze(requests);

      if (state.requestDetails) {
        const req = reqList.find((r) => r.id === state.requestDetails.id);
        state.requestDetails = {
          ...req,
          ...state.requestDetails,
        };
      }

      state.requests = reqList;
      state.requestsLoading = false;
    },
    SET_REQUEST_DETAILS: (state, requestDetails) => {
      state.requestDetails = Object.freeze(requestDetails);
    },
    SET_COPY_REQUEST: (state, copyRequest) => {
      state.copyRequest = Object.freeze(copyRequest);
    },
  },
  actions: {
    SET_COPY_REQUEST({ commit }, copyRequest) {
      commit('SET_COPY_REQUEST', copyRequest);
    },
    async REQUEST_OUTSIDE_THE_PET({ commit, dispatch }, model) {
      const data = cloneDeep(model);

      const attFields = [
        'attachments_LastCargoDescriptionClean',
        'attachments_LastCargoDescriptionNotClean',
        'attachments_ReportGasExpert',
      ];
      data.attachments = (data.attachments ?? []).concat(...attFields.map((fieldName) => data[fieldName] || []));

      attFields.forEach((fieldName) => {
        delete data[fieldName];
      });

      const response = await postRequestOutsideThePet(data);

      // TODO: HAMISTIRF-22874 deze situatie kan niet voorkomen id werkelijkheid
      if (!response) {
        return;
      }

      commit('SET_REQUEST_DETAILS', null);
      return dispatch('LOAD_REQUESTS');
    },
    async REQUEST_REPAIR({ commit, dispatch }, model) {
      const data = cloneDeep(model);
      const response = await postRequestRepair(data);
      if (!response) {
        return;
      }

      commit('SET_REQUEST_DETAILS', null);
      return dispatch('LOAD_REQUESTS');
    },
    async REQUEST_TRANSPORT({ commit, dispatch }, model) {
      const data = createApplicationHttpPostBody('TRANSPORT', model);
      const response = await postRequestGenericApplication(data);

      if (!response) {
        return;
      }

      commit('SET_REQUEST_DETAILS', null);
      return dispatch('LOAD_REQUESTS');
    },
    async REQUEST_OUDE_MAAS({ commit, dispatch }, model) {
      const data = createApplicationHttpPostBody('OUDEMAAS', model);
      const response = await postRequestGenericApplication(data);

      if (!response) {
        return;
      }

      commit('SET_REQUEST_DETAILS', null);
      return dispatch('LOAD_REQUESTS');
    },
    async LOAD_REQUEST_DETAILS({ commit, state, getters }, { id, type }) {
      const map = {
        MOORING: getOutsideThePetRequestDetails,
        REPAIR: getRequestRepairRequestDetails,
      };

      const detailsApiRequest = map[type.toUpperCase()] ?? getGenericRequestDetails;
      if (!id || !detailsApiRequest) {
        return {};
      }

      const { data } = await detailsApiRequest(id);
      if (!data) {
        return {};
      }

      const requestOverview = state.requests?.find(
        (request) => request.id === id && request.type.toUpperCase() === type.toUpperCase()
      ) ?? { id };

      commit('SET_REQUEST_DETAILS', {
        type,
        ...requestOverview,
        request: {
          ...data,
        },
      });
      return data;
    },
    async LOAD_REQUESTS({ commit }) {
      commit('SET_REQUESTS_LOADING', true);
      try {
        const { data } = await getRequestsList();
        const groupedRequests = data.map((request) => ({
          id: request.externalId,
          type: request.type,
          ucrn: request.ucrn,
          request: {
            ...request,
            id: request.externalId,
          },
        }));

        commit('SET_REQUESTS', groupedRequests);
      } catch (e) {
        notify({
          type: 'warning',
          title: t('error.LOAD_REQUESTS.title'),
          text: t('error.LOAD_REQUESTS.text'),
        });
      } finally {
        commit('SET_REQUESTS_LOADING', false);
      }
    },
  },
};
