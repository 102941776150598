import store from '../../store';
import router from '../../router';
import i18n from '../../i18n';
import { notify } from '@kyvg/vue3-notification';

const t = i18n.global.t;

export default (promise) => {
  promise.then(async (res) => {
    switch (res.status) {
      case 401:
        await store.dispatch('CLEAR_SESSION_STATE');
        await router.push({ path: '/login', query: { redirect: router.currentRoute.path } });
        break;

      case 403:
        notify({
          type: 'danger',
          title: t('error.403.title'),
          text: t('error.403.text'),
        });
        break;
    }
  });
};
