import mapKeys from 'lodash/mapKeys';
import mapValues from 'lodash/mapValues';
import kebabCase from 'lodash/kebabCase';
import sharedNlNl from 'poronline-shared-vue-components/locales/nl-nl';
import sharedEnGb from 'poronline-shared-vue-components/locales/en-gb';
import nlNl from './nl-nl';
import enGb from './en-gb';

const defaultedNlNl = mapValues({ ...sharedNlNl, ...nlNl }, (value, key) => value || key);
const defaultedEnGb = mapValues({ ...sharedEnGb, ...enGb }, (value, key) => value || key);

const toLocaleKey = (key) =>
  kebabCase(key).replace(
    /(\w{2})(-(\w{2}))/,
    // eslint-disable-next-line no-unused-vars
    (...[all, lang, countryWithDash, country]) => `${lang}${country ? `-${country.toUpperCase()}` : ''}`
  );

const localizationResources = mapKeys({ nlNl: defaultedNlNl, enGb: defaultedEnGb }, (value, key) => toLocaleKey(key));
export const localeOptions = Object.keys(localizationResources);
export default localizationResources;
